import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a onClick={scrollTop} href={'#'}>
                    <img src={'/images/logo.png'} alt="img" />
                  </a>
                </div>
                <address>
                  <h4>Office</h4>
                  <p>Atlanta, GA</p>
                </address>
                {/* <ul className="social-media-icons">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.twitter.com/"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>

                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.pinterest.com/"
                    >
                      <i className="fab fa-pinterest-p" />
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <h4>Our Services</h4>
                <ul className="footer-menu">
                  <li>
                    <a onClick={scrollTop} href={`/service-details`}>
                      Strategy &amp; Research
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service-details`}>
                      Web Development
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service-details`}>
                      Web Solution
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service-details`}>
                      Digital Merketing
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service-details`}>
                      App Design
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service-details`}>
                      App Development
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <h4>Company</h4>
                <ul className="footer-menu">
                  <li>
                    <a onClick={scrollTop} href={`/about`}>
                      About Us
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service`}>
                      Services
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/project`}>
                      Project
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={`/service`}>
                      Pricing Plan
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xl-3">
              <div className="footer-widget">
                <h4>Contacts</h4>
                <div className="number">
                  <div className="num-icon">
                    <i className="fas fa-phone-alt" />
                  </div>
                  <div className="phone">
                    <a rel="noopener noreferrer" href="tel:05661111985">
                      Comming Soon
                    </a>
                  </div>
                </div>
                <div className="office-mail">
                  <div className="mail-icon">
                    <i className="far fa-envelope" />
                  </div>
                  <div className="email">
                    <a
                      rel="noopener noreferrer"
                      href="mailto:contact@universumcode.com"
                    >
                      contact@universumcode.com
                    </a>
                    <a
                      rel="noopener noreferrer"
                      href="mailto:info@universumcode.com"
                    >
                      info@universumcode.com
                    </a>
                  </div>
                </div>
                <div className="address">
                  <div className="address-icon">
                    <i className="fas fa-map-marker-alt" />
                  </div>
                  <p>Atlanta GA</p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright 2022 <b>Universum</b> | Design By{' '}
                    <a
                      rel="noopener noreferrer"
                      href="https://www.universumcode.com/"
                      target="_blank"
                    >
                      UniversumCode
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <a onClick={scrollTop} href={'#'}>
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={'#'}>
                      Terms of Use
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={'#'}>
                      Support Policy
                    </a>
                  </li>
                  <li>
                    <a onClick={scrollTop} href={'#'}>
                      Terms of Service
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="scroll-top opacity">
        <ScrollButton></ScrollButton>
      </div>
    </>
  );
}

export default Footer;

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <i
      className="bi bi-arrow-up"
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
    >
      <span>top</span>
    </i>
  );
};
