import React, { useState } from "react";


function ContactForm() {

const [values, setValues] = useState({
  name: "",
  email: "",
  message: "",
  
});

const [submitted, setSubmitted] = useState(false);
const [valid, setvalid] = useState(false);
const [message, setMessage] = useState("");

const handleNameInputChange = (event) => {
  event.persist();
  setValues((values) => ({
    ...values,
    name: event.target.value,
  }));
};

const handleEmailInputChange = (event) => {
  event.persist();
  setValues((values) => ({
    ...values,
    email: event.target.value,
  }));
};
  
const handleMessageInputChange = (event) => {
  event.persist();
  setValues((values) => ({
    ...values,
    message: event.target.value,
  }));
};


const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    console.log("Success.");
    if (values.name && values.email && values.message) {
      setvalid(true);
      fetch('https://1jg7kg6avh.execute-api.us-east-1.amazonaws.com/dev/send-email', {
       method: 'post',
       headers: {'Content-Type':'text/plain'},
       body: JSON.stringify(values)
      }).then(response => {
        console.log(response);
        setMessage(response);
      });
      
    }
    setSubmitted(true);  
  } catch (err) {
    console.log("Error", err);
  }

  
  console.log(values);
}; 

return (
  <>
      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="contact-form">
                <h3>Have Any Questions</h3>
                <form onSubmit={handleSubmit}>
                    {submitted && valid ? <div class="alert alert-info" role="alert">We will be in touch soon.</div> : null}
                  <div className="row">
                    <div className="col-12">
                      <input
                        id="name"
                        className="form-field"
                        type="text"
                        name="name"
                        placeholder="Enter your name"
                        value={values.name}
                        onChange={handleNameInputChange}/>
                        {submitted && !values.name ? <span className="span">Please Enter a Name</span> : null}
                    </div>
                    <div className="col-xl-6">
                      <input
                        id="email"
                        type="text"
                        name="email"
                        placeholder="Enter your email"
                        value={values.email}
                        onChange={handleEmailInputChange}/>
                        {submitted && !values.email ? <span className="span">Please Enter a Email</span> : null}
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        cols={30}
                        rows={10}
                        placeholder="Your message"
                        defaultValue={values.message}
                        onChange={handleMessageInputChange}/>
                        {submitted && !values.message ? <span className="span">Please Enter a Message</span> : null}
                    </div>
                    <div className="col-12">
                      <input type="submit" value="Send Message" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="google-map">
                <iframe
                  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJjQmTaV0E9YgRC2MLmS_e_mY&key=AIzaSyAWtLalkMa3RJxZiJRUjEwLhV-TiHlVWNs"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  title="Atlanta"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
